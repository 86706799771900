<template>
<!-- 对接管理 -->
  <div class="box100">
    <div class="whiteBg" v-if="!listShow">
      <div class="flexCB">
        <div class="flexAC" style="margin: 16px 0px;">
          <el-input v-model="name"  placeholder="请输入名称" class="inputSearch"></el-input>
          <el-select v-model="eqtid" placeholder="设备类型" class="retSelect">
            <el-option v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <el-button  class="btnSearch"  @click="handleSearch">搜索</el-button>
          <el-button  class="btnReset"  @click="handleReset">重置</el-button>
        </div>
        <div class="el-icon-plus settingAdd"  @click="handleAdd"><span> 新增</span></div>
      </div>
      <div class="table_cont">
        <el-table
          :data="dockList"
          :header-cell-style="{background:'#F6F9FF'}">
          <el-table-column label="序号" width="80">
            <template #default="scope"><span>{{(page - 1) * size + scope.$index + 1}}</span></template>
          </el-table-column>
          <el-table-column
            label="设备型号"
            prop="dock_model_name">
          </el-table-column>
          <el-table-column
            label="厂商"
            prop="manufacturer_name">
          </el-table-column>
          <el-table-column
            label="类型"
            prop="equ_type_name">
          </el-table-column>
          <el-table-column
            label="更新时间"
            prop="creatime">
          </el-table-column>
          <el-table-column
          label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small"  style="color:#5473E8;" @click="handleCount(scope.row)">参数</el-button>
              <el-button type="text" size="small"  style="color:#5473E8;" @click="handleCode(scope.row)">标识码</el-button>
              <el-button type="text" size="small"  style="color:#5473E8;" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button type="text" size="small"  style="color:#FF4C4C;" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>
    <!-- 新增编辑弹窗 -->
    <el-dialog :title="dialogName" :visible.sync="fromShow" center width="460px" class="gyDialog" :close-on-click-modal="false" :before-close="FromCancel">
      <el-form :model="dockfrom" :rules="formRules" ref="dockFrom">
        <el-form-item label="设备型号" prop="eqcid">
          <el-select v-model="dockfrom.eqcid" placeholder="请选择设备型号" :disabled="eqcDisabled" style="width:100%" @change="changeType">
            <el-option v-for="item in eqcList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <div class="flexCB">
          <el-form-item label="类型" style="width:45%" prop="eqtid">
            <el-input v-model="dockfrom.equ_type_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="厂商" style="width:45%"  prop="manuid">
            <el-input v-model="dockfrom.manufacturer_name" disabled></el-input>
          </el-form-item>
        </div>
        <el-form-item label="告警功能" prop="alarm">
          <el-input type="textarea" v-model="dockfrom.alarm" class="resizeNone" :rows="5" show-word-limit maxlength="200" placeholder="请输入告警功能(200字以内)"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="mark">
          <el-input type="textarea" v-model="dockfrom.mark" class="resizeNone" :rows="5" show-word-limit maxlength="200" placeholder="请输入描述内容(200字以内)"></el-input>
        </el-form-item>
        <el-form-item label="设备图片" prop="image" class="equPic">
          <Upload :fileType="'jpg/png/jpeg'" :picture="dockfrom.image"  @uploadSuccess="ImgSuccess"></Upload>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="FromCancel">取消</div>
        <div class="settingConfirm" @click="FromConfirm">确定</div>
      </div>
    </el-dialog>
    <!-- 参数弹窗 -->
    <el-dialog title="参数" :visible.sync="countShow" center width="500px" class="gyDialog" :close-on-click-modal="false" :before-close="countCancel">
      <el-form :model="countfrom" :rules="countFormRules" ref="countFrom">
        <div class="flexSC" style="margin-top:20px;">
          <el-form-item label="设备型号" prop="parid" class="id_box">
            <el-select v-model="countfrom.parid" placeholder="选择参数" class="Row118" @change="selectXh($event)">
              <el-option v-for="item in countList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="max">
            <el-input v-model="countfrom.max" class="Row118" placeholder="最大值"></el-input>
          </el-form-item>
          <el-form-item label="" prop="min">
            <el-input v-model="countfrom.min" class="Row118" placeholder="最小值"></el-input>
          </el-form-item>
          <div class="settingConfirm" @click="countAdd" style="width:60px;margin-left: 0;margin-top: 3px;">添加</div>
        </div>
        <div class="trendsBox specialtrends" v-if="paraList && paraList.length !== 0">
          <div class="pluralBox" v-for="(item, index) in paraList" :key="'bpt-'+index" style="padding: 0px;">
            <label class="plurRig">{{ item.name }} ({{ item.unit }})</label>
            <label class="plurRig">{{ item.max_ }}</label>
            <label class="plurRig">{{ item.min_ }}</label>
            <label @click="countDel(index)" class="el-icon-circle-close plurIco"></label>
          </div>
        </div>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="countCancel">取消</div>
        <div class="settingConfirm" @click="countConfirm">确定</div>
      </div>
    </el-dialog>
    <!-- 标识码 -->
    <div class="form_modle" v-if="listShow">
      <CodeForm :eqcId="eqcId" @cancelBack="cancelBack"></CodeForm>
    </div>
  </div>
</template>

<script>
import { buttGet, buttDel, buttAdd, buttDrop, buttUpdate, equtypeDrop, modelDrop, modelDetail, parameterDrop, buttParGet, buttParCommit } from '@/api/port.js'
import Upload from '@/components/upload.vue'
import CodeForm from '@/components/codeForm.vue'
export default {
  components: {
    Upload,
    CodeForm
  },
  data () {
    return {
      name: '',
      eqtid: '',
      typeList: [],
      dockList: [],
      page: 1,
      size: 10,
      total: 0,
      dialogName: '新增',
      fromShow: false,
      dockfrom: {
        eqcid: '',
        manuid: '',
        manufacturer_name: '',
        eqtid: '',
        equ_type_name: '',
        alarm: '',
        mark: '',
        image: ''
      },
      formRules: {
        eqcid: [
          { required: true, trigger: 'blur', message: '型号不能为空' }
        ],
        manuid: [
          { required: true, trigger: 'blur', message: '厂家不能为空' }
        ],
        eqtid: [
          { required: true, trigger: 'blur', message: '类型不能为空' }
        ],
        alarm: [
          { required: true, trigger: 'blur', message: '告警功能不能为空' },
          { min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'blur' }
        ],
        mark: [
          { required: true, trigger: 'blur', message: '备注不能为空' },
          { min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'blur' }
        ],
        image: [
          { required: true, trigger: 'blur', message: '请上传图片' }
        ],
      },
      eqcList: [], //设备型号
      countShow: false,
      countfrom: {
        parid: '',
        max: '',
        min: '',
        name: '',
        unit: ''
      },
      countFormRules: {
        parid: { required: true, trigger: 'blur', message: '请选择参数' },
        max: { required: true, trigger: 'blur', message: '最大值不能为空' },
        min: { required: true, trigger: 'blur', message: '最小值不能为空' }
      },
      countList: [], //参数下拉数组
      paraList: [],  //参数列表数组
      eqcId: '', //型号id
      listShow: false,
      eqcDisabled: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = {
        name: this.name,
        eqtid: this.eqtid,
        page_index: this.page,
        page_size: this.size
      }
      buttGet(params).then(res => {
        if (res.status === 1) {
          this.dockList = res.data.data
          this.total = res.data.total
        } else {
          this.dockList = []
          this.total = 0
        }
      })

      // 设备类型下拉
      equtypeDrop().then(res => {
        if (res.status === 1) {
          this.typeList = res.data
        }
      })
    },
    selectXh (val) {
      if (val !== '') {
        let _obj = this.checkKey(val)
        this.countfrom.name = _obj.name
        this.countfrom.unit = _obj.unit
      }
    },
    checkKey (val) {
      let obj = {}
      this.countList.forEach((item) =>{
        if(item.id === val) {
          obj = item
        }
      })
      return obj
    },
    // 参数弹窗
    handleCount (row) {
      this.countShow = true
      // 参数下拉
      parameterDrop ().then(res => {
        if (res.status === 1) {
          this.countList = res.data
        }
      })
      // 参数列表查询
      buttParGet ({eqcid: row.eqcid}).then(res => {
        if (res.status === 1) {
          this.paraList = res.data
        }
      })
      this.eqcId = row.eqcid
    },
    // 参数添加
    countAdd () {
      this.$refs.countFrom.validate((valid) => {
        if (!valid) return
        let _obj = { 
          parid: this.countfrom.parid,
          max_: this.countfrom.max,
          min_: this.countfrom.min,
          name: this.countfrom.name,
          unit: this.countfrom.unit
        }
        this.paraList.push(_obj)
        this.$refs.countFrom.resetFields()
        // console.log(this.paraList)
      })
    },
    // 参数删除 
    countDel (eq) {
      this.paraList.splice(eq, 1)
    },
    // 参数取消
    countCancel () {
      this.countShow = false
      this.$nextTick(() => {
        this.$refs.countFrom.resetFields()
        this.countfrom = {}
      })
    },
    // 参数提交
    countConfirm () {
      let arr = []
      if(this.paraList.length > 0) {
        this.paraList.forEach(item => {
          let obj = {
            id: item.parid,
            min: item.min_,
            max: item.max_
          }
          arr.push(obj)
        })
      }
      const params = {
        eqcid: this.eqcId,
        setlist: arr
      }
      buttParCommit(params).then(res => {
        if (res.status === 1) {
          this.$message.success(res.message)
          this.countCancel()
          this.init()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 标识码弹窗
    handleCode (row) {
      this.listShow = true
      this.eqcId = row.eqcid
    },
    // 标识码返回
    cancelBack () {
      this.listShow = false
    },
    // 选择设备型号后带出类型和厂商
    changeType (val) {
      modelDetail({id: val}).then(res => {
        if (res.status === 1) {
          this.dockfrom.eqtid = res.data.eqtid
          this.dockfrom.manuid = res.data.manuid
          this.dockfrom.manufacturer_name = res.data.manufacturer_name
          this.dockfrom.equ_type_name = res.data.equ_type_name
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getInit () {
      // 设备型号下拉
      modelDrop().then(res => {
        if (res.status === 1) {
          this.eqcList = res.data
        }
      })
    },
    // 对接新增
    handleAdd () {
      this.fromShow = true
      this.dialogName = '新增'
      this.eqcDisabled = false
      this.getInit()
      this.$nextTick(() => {
        this.$refs.dockFrom.resetFields()
        // this.dockfrom = {}
      })
    },
    // 对接编辑弹窗
    handleEdit (row) {
      this.fromShow = true
      this.dialogName = '编辑'
      this.dockfrom = { ...row }
      this.eqcDisabled = true
      this.getInit()
    },
    // 图片展示
    ImgSuccess (res) {
      this.dockfrom.image = res.url
    },
    // 对接取消
    FromCancel () {
      this.$refs.dockFrom.resetFields()
      this.fromShow = false
      this.dockfrom.manufacturer_name = ''
      this.dockfrom.equ_type_name = ''
    },
    // 对接新增编辑提交
    FromConfirm () {
      this.$refs.dockFrom.validate((valid) => {
        if (!valid) return
        const params = {
          id: this.dockfrom.id,
          eqcid: this.dockfrom.eqcid,
          manuid: this.dockfrom.manuid,
          eqtid: this.dockfrom.eqtid,
          alarm: this.dockfrom.alarm,
          mark: this.dockfrom.mark,
          image: this.dockfrom.image,
        }
        if (this.dialogName === '新增') {
          buttAdd(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.FromCancel()
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        } else if (this.dialogName === '编辑') {
          buttUpdate(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.FromCancel()
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 删除
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        buttDel({ id: row.id }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.init()
    },
    handleReset () {
      this.name = ''
      this.eqtid = ''
      this.page = 1
      this.init()
    }
  }
}
</script>

<style scoped>
.id_box >>> .el-form-item__label {
  position: absolute;
  top: 60px;
}
.equPic >>> .el-form-item__label {
  float: none !important;
}
.trendsBox{
  /* margin-top: 22px; */
  margin-bottom: 20px;
}
.pluralBox{
  width: 100%;
  height: 38px;
  line-height: 38px;
  background: #F5F9FD;
  border-radius: 4px;
  padding: 0px 16px;
  box-sizing: border-box;
  margin-top: 2px;
  display: flex;
  align-items: center;
  position: relative;
}
.specialtrends{
  width: 420px;
}
.plurRig{
  width: 118px;
  text-align: center;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
}
.plurIco{
  right: 10px;
  color: #FF6676;
  padding-left: 20px;
}
</style>
