<template>
  <div class="box100">
    <p class="formTitle">标识码</p>
    <div class="formContent">
      <div class="box100 scrollBox">
        <el-form :model="codefrom" :rules="formRules" ref="codeFrom" class="smallForm">
          <div v-for="(item, index) in codeList" :key="index" class="code_box">
            <div class="flexS">
              <el-form-item :label="'标识码'+(index+1)+'配置'" prop="tranid">
                <el-select v-model="item.tranid" placeholder="请选择中转平台" class="Row244">
                  <el-option v-for="item in tranList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="twayid" style="margin-top:40px;">
                <el-select v-model="item.twayid" placeholder="传输方式" class="Row118" style="margin-left: 8px;">
                  <el-option v-for="item in wayList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="flexS" >
              <el-form-item label="" prop="name">
                <el-select v-model="codefrom.name" placeholder="字段名称" class="Row118">
                  <el-option v-for="item in fieldList"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="type">
                <el-select v-model="codefrom.type" placeholder="显示方式" class="Row118" style="margin-left: 8px;">
                  <el-option v-for="item in showList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <div class="settingConfirm" @click="codeAdd(index)" style="width:60px;margin-left: 8px;margin-top: 3px;">添加</div>
            </div>
            <div class="trendsBox specialtrends" >
              <div class="pluralBox" v-for="(fie, index1) in item.field" :key="'bpt-'+index1" style="padding: 0px;background: transparent;">
                <label class="plurRig">{{ fie.name }}</label>
                <label class="plurRig">
                  <span v-if="fie.type === 1">输入框</span>
                  <span v-if="fie.type === 2">展示框</span>
                </label>
                <label @click="codeDel(index,index1)" class="el-icon-circle-close plurIco"></label>
              </div>
            </div>
          </div>
          <div class="addConfirm el-icon-circle-plus-outline" @click="handYqAdd()"><span style="margin-left:5px;">添加字段列表</span></div>
        </el-form>
        <div class="flexCE" style="margin-right:30px;">
          <div class="settingCancel" @click="codeCancel">取消</div>
          <div class="settingConfirm" @click="codeConfirm">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { buttIndetGet, buttWayDrop, buttFieldDrop, buttIndetComt, transferDrop } from '@/api/port.js'
export default {
  props: ['eqcId'],
  data () {
    return {
      codefrom: {
        tranid: '',
        twayid: '',
        field: [],
        name: '',
        type: ''
      },
      formRules: {
        // tranid: { required: true, trigger: 'blur', message: '请选择中转平台' },
        name: { required: true, trigger: 'blur', message: '请选择字段名称' },
        type: { required: true, trigger: 'blur', message: '请选择显示方式' },
      },
      codeList: [],
      tranList: [], //中转平台列表
      wayList: [], //传输方式列表
      fieldList: [], //字段名称
      showList: [{name: '输入框', id:1}, {name: '展示框', id:2}], //显示方式
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      // 标识码列表查询
      buttIndetGet({eqcid: this.eqcId}).then(res => {
        if (res.status === 1) {
          this.codeList= res.data
          console.log(this.codeList)
        }
      })
      // 中转平台下拉
      transferDrop({id: this.eqcId}).then(res => {
        if (res.status === 1) {
          this.tranList= res.data
        }
      })
      // 传输方式
      buttWayDrop().then(res => {
        if (res.status === 1) {
          this.wayList= res.data
        }
      })
      // 字段名称
      buttFieldDrop().then(res => {
        if (res.status === 1) {
          this.fieldList= res.data
        }
      })
    },
    // 添加
    codeAdd (e) {
      this.$refs.codeFrom.validate((valid) => {
        if (!valid) return
        let _obj = { 
          name: this.codefrom.name,
          type: this.codefrom.type
        }
        this.codeList[e].field.push(_obj)
        this.$refs.codeFrom.resetFields()
        // console.log(this.paraList)
      })
    },
    // 删除
    codeDel (eq,eq1) {
      this.codeList[eq].field.splice(eq1, 1)
    },
    // 取消
    codeCancel () {
      this.$emit('cancelBack')
    },
    // 确定
    codeConfirm () {
      let arr = []
      if(this.codeList.length > 0) {
        this.codeList.forEach(item => {
          let obj = {
            tranid: item.tranid,
            twayid: item.twayid,
            field: item.field
          }
          arr.push(obj)
        })
      }
      const params = {
        eqcid: this.eqcId,
        value: arr
      }
      buttIndetComt(params).then(res => {
        if (res.status === 1) {
          this.$message.success(res.message)
          this.codeCancel()
          this.init()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handYqAdd () {
      let _obj = {
        tranid: '',
        twayid: '',
        field: []
      }
      this.codeList.push(_obj)
      console.log(this.codeList)
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>
.id_box >>> .el-form-item__label {
  position: absolute;
  top: 20px;
}
.addConfirm {
  width: 200px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #5473E8;
  font-size: 14px;
  font-weight: 400;
  color: #5473E8;
  line-height: 34px;
  cursor: pointer;
  margin-top: 20px;
}
.code_box {
  border-bottom: 1px solid #F0F3F8;
}
.plurRig{
  background: #f5f9fd;
}
</style>
